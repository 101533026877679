
import ThemeMaker from "../../components/thememaker";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "../searchKeyword/search.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { response } from "express";
import { data } from "jquery";
import { CLOSING } from "ws";
import { AnyMxRecord } from "dns";
import jwt_decode from "jwt-decode";
import config from "../../config/config";
var $ = require("jquery");


const TxnIdLog = () => {
  const {baseUrl}= config;
  $(document).ready(function () {
    $("table.display").DataTable();
  });
  const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { data } = decoded;
  const [search, setSearch] = useState([]);
  const [searchKey, setSearchKey] = useState(
    {
    txnid:"",
    action:""
    }

  );
  const [dispdata , setDispdata] = useState<any[]>([  {}]);

  var i = 1;



  // Search item by name

  const searchRecords = async (e:any) => {
    e.preventDefault();
    // setSearchKey(searchKey);
    console.log('search key 36==>>',searchKey);
    
    const resData  = await axios({
      url:`${baseUrl}/getlogs_txn`,
      method:"POST",
      headers: {
        Authorization: ` ${token}`
    },
      data:{
        searchKey
    }
     
    });
    const dispdata= resData.data.all_logsdata;
    console.log('resData.data.logsdata4 pg 48==>',resData.data.all_logsdata)
    console.log("line 45===>>>", {...dispdata});
    setDispdata(dispdata);
    console.log('line 48',dispdata);

  };
  
  const exportData = () => {
  const dataexp = dispdata &&
    dispdata.map((item, index) => {
     return {
       "body": item?.body,
       "response": item?.response
     }
    })
    for(i=0; i<dataexp.length; i++){
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(dataexp[i])
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${dataexp[i].body.context.action}_data.json`;

    link.click();  
    }
    
  };
  
  return (
    <ThemeMaker>
      <div className="container-fluid row">  
      <h4 className="text-center text-success ">
          <b>Search Data By Transaction ID</b>
        </h4> <button type="button" onClick={exportData}>
        Export Data
      </button></div>
        <div  className="container-fluid row" style={{border:'#000'}}> 
        <div className="col-12">
        <form  onSubmit={(e:any)=>searchRecords(e)}>
        <div className="form-outline harish111111 col-6">
          <input
            type="text"
            id="form1"
            name='txnid'
            value={searchKey.txnid}
            onChange={(e) => setSearchKey({...searchKey, [e.target.name]:e.target.value})}
            className="form-control"
            placeholder="Txn Id"
          />
        <select name='action' 
        value={searchKey.action}
        onChange={(e) => setSearchKey({...searchKey, [e.target.name]:e.target.value})}
        className="form-control col-6">
            <option selected disabled>Select option</option>
            <option value="search">Search</option>
            <option value="select">Select</option>
            <option value="init">Init</option>
            <option value="confirm">Confirm</option>
            <option value="cancel">Cancel</option>
      </select>
        </div>
        <button
          type="submit"
          // onClick={searchRecords}
          className="btn btn-success btn1111"
        >
          Search
        </button>
      </form></div> 
        </div>
      {/* <div className="container-fluid row">
      <form  onSubmit={(e:any)=>searchRecords(e)}>
        <div className="form-outline harish111111 col-6">
          <input
            type="text"
            id="form1"
            name='txnid'
            value={searchKey.txnid}
            onChange={(e) => setSearchKey({...searchKey, [e.target.name]:e.target.value})}
            className="form-control"
            placeholder="Txn Id"
          />
        <select name='action' 
        value={searchKey.action}
        onChange={(e) => setSearchKey({...searchKey, [e.target.name]:e.target.value})}
        className="form-control col-6">
            <option selected disabled>Select option</option>
            <option value="search">Search</option>
            <option value="select">Select</option>
            <option value="init">Init</option>
            <option value="confirm">Confirm</option>
            <option value="cancel">Cancel</option>
      </select>
        </div>
        <button
          type="submit"
          // onClick={searchRecords}
          className="btn btn-success btn1111"
        >
          Search
        </button>
      </form>
        
      </div> */}
<div style={{overflowX: 'scroll'}}>
<table className="table table-striped" >
        <thead>
          <tr>
            <th>Sr.No.</th>
            <th>Action</th>
            <th>Transaction Id</th>
            <th>bap_id</th>
            <th>bpp id</th>
            <th>call_back</th>
            <th>created_at</th>
            <th>updatedAt</th>
          </tr>
        </thead>

        <tbody>
         
          {
            dispdata &&
          dispdata.map((item, index) => {
       
            return (
              
              <>
                <tr key={index}>
                  <td>{item?._id}</td>
                  <td>{item?.body?.context?.action}</td>
                  <td>{item?.body?.context?.transaction_id}</td>
                  <td>{item?.body?.context?.bap_id}</td>
                  <td>{item?.body?.context?.bpp_id}</td>
                  <td>{item?.response?.context?.action}</td>
                  <td>{item?.createdAt}</td>
                  <td>{item?.updatedAt}</td>

                </tr>
              
              
              </>
         
                  )
       
            })
          }
        </tbody>
        </table>
</div>
 
      
    </ThemeMaker>
  );
};

export default TxnIdLog;
