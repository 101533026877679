import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import { toast } from 'react-toastify';
import TableRow from '@mui/material/TableRow';
import config from "../../config/config";
import jwt_decode from "jwt-decode";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useLocation, useHistory } from "react-router-dom";



interface Column {
    id: 'variant' | 'price' | 'fabric' | 'mrp' | 'quantity' | 'size_chart' | 'gender';
    label: string;
    minWidth?: number;
    align?: 'center';
    format?: (value: number) => string;
}

// interface Data {
//     variant: string;
//     price: string;
//     quantity: number;
//     sku: number;

// }

// function createData(
//     variant: string,
//     price: string,
//     quantity: number,
//     sku: number,
// ): Data {
//     return { variant, price, quantity, sku };
// }

const columns: readonly Column[] = [
    { id: 'variant', label: 'Variant', minWidth: 170, align: 'center', },
    { id: 'price', label: 'Price', minWidth: 170, align: 'center', },
    { id: 'mrp', label: 'MRP', minWidth: 170, align: 'center', },
    { id: 'fabric', label: 'Fabric', minWidth: 170, align: 'center', },
    { id: 'quantity', label: 'Quantity', minWidth: 170, align: 'center', format: (value: number) => value.toLocaleString('en-US'), },
    // { id: 'sku', label: 'SKU', minWidth: 170, align: 'center', format: (value: number) => value.toLocaleString('en-US'), },
    { id: 'size_chart', label: 'Size Chart', minWidth: 170, align: 'center', },
    { id: 'gender', label: 'Gender', minWidth: 170, align: 'center', }
];

export default function VairantTables({ dataList, dataSet }: any) {
    const { baseUrl } = config;
    const history = useHistory();
    const location = useLocation();
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [pageNamem, setPageNamem] = useState(false);
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { data: any } = decoded;
    const [variants, setVariants]: any = useState([]);
    let params = useParams();
    // let history = useHistory();
    const { id }: any = params;

    // const handleChangePage = (_event: unknown, newPage: number) => {
    //     setPage(newPage);
    // };
    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };

    const handleInputChange = (size: any, color: any, field: any, value: any) => {
        setVariants((prevData: any) => {
            const updatedData = prevData.map((item: any) =>
                item.colour === color && item.size === size ? { ...item, [field]: value } : item
            );
            if (!updatedData.some((item: any) => item.colour === color && item.size === size)) {
                updatedData.push({ colour: color, size: size, [field]: value });
            }
            return updatedData;
        });
    };
    // function handleDeleteRow(sizeIndex: number, colorIndex: number) {
    //     setVariants((prevVariants: any) => {
    //         const updatedVariants = [...prevVariants];
    //         const indexToDelete = sizeIndex * dataSet?.color?.length + colorIndex;
    //         if (indexToDelete >= 0 && indexToDelete < updatedVariants.length) {
    //             updatedVariants.splice(indexToDelete, 1);
    //         } else {
    //             console.error("Invalid index to delete");
    //         }
    //         return updatedVariants;
    //     });
    // }




    const addVariants = async () => {
        try {
            const variantData = {
                productId: id,
                variants: variants,
            };
            console.log("variantData", variantData);
            const response = await axios({
                url: `${baseUrl}/product/add_variant`,
                method: "POST",
                headers: {
                    Authorization: `${token}`,
                },
                data: variantData,
            });
            const { data } = response?.data;
            const hasData = data && data?.length > 0;
            // setFilteredData(hasData ? data : []);
        } catch (error) {
            console.log("error", error);
        }
    };

    const updateVariant = async () => {
        try {
            const variantData = {
                _id: id,
                variants: variants,
            };
            await axios({
                url: `${baseUrl}/product/update`,
                method: "PUT",
                headers: {
                    Authorization: token,
                },
                data: variantData,
            });

            toast.success("Variants Data Updated !");
            // history.push('/productlist');
            await new Promise((resolve) => setTimeout(resolve, 1000));
            window.location.reload();
        } catch (error) {
            toast.error("404 error ! please reach to support@ens.enterprises", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    useEffect(() => {
        const initDataList: any = [...dataList];
        if (initDataList?.length > 0) {
            setVariants(initDataList)
        }
        setPageNamem(id?.length > 0 ? true : false);
    }, [dataList, id?.length]);

    // console.log("variants", variants)
    // console.log("dataSet", dataSet)
    // console.log("VarTable_initial dataList", dataList)

    return (
        <div className='mt-3'>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        {(dataSet.size.length > 0 || dataSet.color.length > 0) ? <TableHead >
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead> : null}
                        {dataSet?.size?.map((size: string, sizeIndex: number) => {
                            return (dataSet?.color?.map((color: string, colorIndex: number) => (
                                <TableRow key={`${size}-${color}`} hover role="checkbox" tabIndex={-1}>
                                    <TableCell align="center" style={{ backgroundColor: "lightgray" }}>
                                        {`${size}-${color}`}
                                    </TableCell>
                                    <TableCell align="center">
                                        <input type="text" className="form-control"
                                            value={(variants.find((item: any) => item.size === size && item.colour === color)?.price || null)}
                                            onChange={(e) => handleInputChange(size, color, 'price', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <input type="text" className="form-control"
                                            value={(variants.find((item: any) => item.size === size && item.colour === color)?.mrp || null)}
                                            onChange={(e) => handleInputChange(size, color, 'mrp', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <select name="" id="" className='form-control' value={(variants.find((item: any) => item.size === size && item.colour === color)?.fabric || null)}
                                            onChange={(e) => handleInputChange(size, color, 'fabric', e.target.value)}>
                                            <option selected disabled>Select</option>
                                            <option value="alpha">Alpha</option>
                                            <option value="acrylic">Acrylic</option>
                                            <option value="art silk">Art silk</option>
                                            <option value="bamboo">Bamboo</option>
                                            <option value="chambray">Chambray</option>
                                            <option value="chanderi cotton">Chanderi cotton</option>
                                            <option value="chanderi silk">Chanderi silk</option>
                                            <option value="chiffon">Chiffon</option>
                                            <option value="cotton">Cotton</option>
                                            <option value="cotton blend">Cotton blend</option>
                                            <option value="cotton cambric">Cotton cambric</option>
                                            <option value="cotton linen">Cotton linen</option>
                                            <option value="cotton silk">Cotton silk</option>
                                            <option value="crepe">Crepe</option>
                                            <option value="denim">Denim</option>
                                            <option value="dupion silk">Dupion silk</option>
                                            <option value="elastane">Elastane</option>
                                            <option value="georgette">Georgette</option>
                                            <option value="jacquard">Jacquard</option>
                                            <option value="jute">Jute</option>
                                            <option value="jute cotton">Jute cotton</option>
                                            <option value="jute silk">Jute silk</option>
                                            <option value="khadi cotton">Khadi cotton</option>
                                            <option value="kora muslin">Kora muslin</option>
                                            <option value="lace">Lace</option>
                                            <option value="leather">Leather</option>
                                            <option value="linen">Linen</option>
                                            <option value="mulmul">Mulmul</option>
                                            <option value="modal">Modal</option>
                                            <option value="net">Net</option>
                                            <option value="nylon">Nylon</option>
                                            <option value="organza">Organza</option>
                                            <option value="paper silk">Paper silk</option>
                                            <option value="pashmina">Pashmina</option>
                                            <option value="poly chiffon">Poly chiffon</option>
                                            <option value="poly crepe">Poly crepe</option>
                                            <option value="poly georgette">Poly georgette</option>
                                            <option value="poly silk">Poly silk</option>
                                            <option value="polycotton">Polycotton</option>
                                            <option value="polyester">Polyester</option>
                                            <option value="rayon">Rayon</option>
                                            <option value="rayon slub">Rayon slub</option>
                                            <option value="satin">Satin</option>
                                            <option value="shantoon">Shantoo</option>
                                            <option value="silk">Silk</option>
                                            <option value="silk blend">Silk blend</option>
                                            <option value="soft silk">Soft silk</option>
                                            <option value="super net">Super net</option>
                                            <option value="synthetic">Synthetic</option>
                                            <option value="taffeta silk">Taffeta silk</option>
                                            <option value="tissue">Tissue</option>
                                            <option value="tussar silk">Tussar silk</option>
                                            <option value="velvet">Velvet</option>
                                            <option value="vichitra silk">Vichitra silk</option>
                                            <option value="viscose">Viscose</option>
                                            <option value="viscose rayon">Viscose rayon</option>
                                            <option value="voile">Voile</option>
                                            <option value="wool">Wool</option>
                                        </select>
                                    </TableCell>
                                    <TableCell align="center">
                                        <input type="text" className="form-control"
                                            value={(variants.find((item: any) => item.size === size && item.colour === color)?.quantity || null)}
                                            onChange={(e) => handleInputChange(size, color, 'quantity', e.target.value)}
                                        />
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        <input type="text" className="form-control"
                                            value={(variants.find((item: any) => item.size === size && item.colour === color)?.sku || null)}
                                            onChange={(e) => handleInputChange(size, color, 'sku', e.target.value)}
                                        />
                                    </TableCell> */}
                                    <TableCell align="center">
                                        <input
                                            type="file"
                                            className="form-control"
                                            style={{ lineHeight: "1" }}
                                            onChange={(e) => handleInputChange(size, color, 'size_chart', e.target.files?.[0].name)}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <select name="" id="" className='form-control' value={(variants.find((item: any) => item.size === size && item.colour === color)?.gender || null)}
                                            onChange={(e) => handleInputChange(size, color, 'gender', e.target.value)}>
                                            <option selected disabled>Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="boy">Boy</option>
                                            <option value="girl">Girl</option>
                                            <option value="infant">Infant</option>
                                            <option value="unisex">Unisex</option>
                                        </select>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        <button onClick={() => handleDeleteRow(sizeIndex, colorIndex)}>Delete</button>
                                    </TableCell> */}
                                </TableRow>
                            )));
                        })}
                    </Table>
                </TableContainer>
            </Paper>
            <div className='my-3 d-flex justify-content-end'>
                {
                    pageNamem === true ? (
                        <button type="button" className="btn btn-sm btn-outline-success" onClick={() => updateVariant()}>Update</button>
                    ) : (
                        <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => addVariants()}>Add Variants</button>
                    )
                }
            </div>
        </div>
    )
}
