import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import moment from 'moment';
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import "../orderlist/orderlistPopus.css";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import ThemeMaker from "../../components/thememaker";
import { Modal as AntModal } from "antd";
// import OrderList from "../orderlist";
import config from "../../config/config"
import { Paginated } from "../../components/Paginated";

var $ = require("jquery");

const CancelReturn = () => {
  const { baseUrl } = config;
  const [popup, setPopup] = useState(false);
  const [orderpopup, setOrderpopup] = useState([]);
  const [orderList, setOrderList] = useState<Orders[]>([]);
  const [updateList, setupdateList] = useState([]);
  const [FilteredData, setFilteredData]: any = useState([]);
  const [popupFilter, setpopupFilter]: any = useState([]);
  const token: any = sessionStorage.getItem("isLogedIn");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const decoded: any = jwt_decode(token);
  const { user, product, order, order_manage } = decoded.data.permission;
  const history = useHistory();
  const [value, setValue] = useState("In-progress");
  const [status, setstatus] = useState();
  const [returnprovider, setreturnprovider] = useState();
  const [returnitemcount, setreturnitemcount] = useState();
  const [returncontext, setreturncontext] = useState();
  const [loading, setloading] = useState(true);
  const [listloading, setlistloading] = useState(true);
  const closeModel: any = useRef();
  const [error, setError] = useState("");
  const [_id, setId] = useState("");

  // const changeStatus = async (orderId: any, itemId: any, reasonID: any) => {
  //   // console.log("====================================");
  //   console.log("orderId", orderId);
  //   console.log("itemId", itemId);
  //   console.log("reasonID", reasonID);

  //   const returnContextData = {
  //     context: returncontext,
  //     message: {
  //       update_target: "item",
  //       order: {
  //         id: orderId,
  //         state: status,
  //         provider: {
  //           id: returnprovider,
  //         },
  //         items: [
  //           {
  //             id: itemId,
  //             quantity: {
  //               count: returnitemcount,
  //             },
  //             tags: {
  //               status: status,
  //             },
  //           },
  //         ],
  //       },
  //     },
  //   };
  //   console.log("returnContextData 67", returnContextData);
  //   console.log("====================================");

  //   try {
  //     const { data } = await axios({
  //       url: `${baseUrl}/orders/itemstatus`,
  //       method: "PUT",
  //       headers: {
  //         Authorization: ` ${token}`
  //       },
  //       data: {
  //         order_id: orderId,
  //         item_id: itemId,
  //         orderStatus: status,
  //       },
  //     });
  //     console.log("sas => ", data);
  //     if (data.status === 200) {
  //       // alert("status updating");
  //       // toast.success("Status updated successfully")
  //       toast.success(`${status} successfully`);
  //     } else {
  //       alert("404 not found !");
  //     }
  //     setloading(false);
  //     setTimeout(() => {
  //       $("#dataTable").DataTable();
  //     }, 1000);
  //     // console.log("data>>>>>>", data);
  //   } catch (error) {
  //     setloading(false);
  //   }

  //   // try {
  //   //   const { data } = await axios({
  //   //     url: `${baseUrl}/ondc/Return_Approved`,
  //   //     method: "PUT",
  //   //     headers: {
  //   //       Authorization: ` ${token}`
  //   //     },
  //   //     data: returnContextData,
  //   //   });
  //   //   console.log("sas => ", data);
  //   //   if (data.status === 200) {
  //   //     // alert("status updating on buyyer");
  //   //   } else {
  //   //     // alert("404 not found ! buyerer");
  //   //   }
  //   //   setloading(false);
  //   //   setTimeout(() => {
  //   //     $("#dataTable").DataTable();
  //   //   }, 1000);
  //   //   // console.log("data>>>>>>", data);
  //   // } catch (error) {
  //   //   setloading(false);
  //   // }
  //   try {
  //     const { data } = await axios({
  //       url: `${baseUrl}/Unsolicated_Update`,
  //       method: "POST",
  //       headers: {
  //         Authorization: token
  //       },
  //       data: {
  //         orderid: orderId,
  //         fulfillment_cancel_id: reasonID
  //       },
  //     });
  //     console.log("console => ", data);
  //     if (data.status === 200) {
  //     } else {
  //     }
  //     setloading(false);
  //     setTimeout(() => {
  //       $("#dataTable").DataTable();
  //     }, 1000);
  //     // console.log("data>>>>>>", data);
  //   } catch (error) {
  //     setloading(false);
  //   }
  // };


  const changeStatus = async (orderId: any, itemId: any, reasonID: any) => {
    console.log("Order Info: ", { orderId, itemId, reasonID });
    const returnContextData = {
      context: returncontext,
      message: {
        update_target: "item",
        order: {
          id: orderId,
          state: status,
          provider: {
            id: returnprovider,
          },
          items: [
            {
              id: itemId,
              quantity: {
                count: returnitemcount,
              },
              tags: {
                status: status,
              },
            },
          ],
        },
      },
    };

    const resetTableAndLoading = () => {
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    };

    const handleAxiosError = (error: any) => {
      console.error("Error occurred:", error);
      setloading(false);
    };

    try {
      const [itemStatusResponse, unsolicitedUpdateResponse] = await Promise.all([
        axios({
          url: `${baseUrl}/orders/itemstatus`,
          method: "PUT",
          headers: {
            Authorization: token,
          },
          data: {
            order_id: orderId,
            item_id: itemId,
            orderStatus: status,
          },
        }),
        // axios({
        //   url: `${baseUrl}/ondc/Return_Approved`,
        //   method: "PUT",
        //   headers: {
        //     Authorization: token,
        //   },
        //   data: returnContextData,
        // }),
        axios({
          url: `${baseUrl}/Unsolicated_Update`,
          method: "POST",
          headers: {
            Authorization: token,
          },
          data: {
            orderid: orderId,
            fulfillment_cancel_id: reasonID,
          },
        }),
      ]);

      if (itemStatusResponse.status === 200) {
        toast.success(`${status} updated successfully`);
      }
      // if (returnApprovedResponse.data.status === 200) {
      //   console.log("Return approved updated successfully");
      // } else {
      //   alert("Error updating return approval");
      // }
      // console.log("unsolicitedUpdateResponse", unsolicitedUpdateResponse);
      // console.log("itemStatusResponse", itemStatusResponse);
      if (unsolicitedUpdateResponse.status === 200) {
        toast.success(`Unsolicited update successful`);
      } else {
        alert("Error in unsolicited update");
      }
      resetTableAndLoading();
    } catch (error) {
      handleAxiosError(error);
    }
  };


  const getOrderList = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseUrl}/orders/npdata`,
        method: "GET",
        headers: {
          Authorization: token
        },
      });
      setOrderList(data.reverse());
      setloading(false);
      console.log("Product Data>>", data);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {
      setloading(false);
      setError("error");
    }
  };

  useEffect(() => {
    getOrderList();
  }, []);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  // const StatusChange = (event: any) => {
  //   setstatus(event.target.value);
  // };

  const changeReturnStatus = (item: any, selectedStatus: any) => {
    console.log(
      "changeReturnStatus item: ",
      item,
      "changeReturnStatus: selectedStatus",
      selectedStatus
    );
    item.status = selectedStatus;
    setstatus(selectedStatus);
    setOrderpopup([...orderpopup]);
  };

  // const changeStatusseller = async (OrderId: any, OrderContext: any) => {
  //   console.log("orderId", OrderId);
  //   console.log("OrderContext", OrderContext);
  //   // context.message_id = uuidv4();
  //   const contextdata = {
  //     OrderContext,
  //     message: {
  //       order_id: OrderId,
  //     },
  //   };
  //   console.log("contaxtdata", contextdata);
  //   try {
  //     const { data } = await axios({
  //       url: `https://ondc.hulsecure.in/preprod/v1/Return_Approved`,
  //       method: "PUT",
  //       data: contextdata,
  //     });
  //     console.log("sas => ", data);
  //     toast.success("Status updated by seller app");
  //     console.log("data>>>>>>", data);
  //   } catch (error: any) {
  //     console.log("error", error);
  //     toast.error(`Error : ${error.message}`);
  //   }
  // };

  // const CancelItem = async (OrderId: any, item_id: any) => {
  //   console.log("orderId", OrderId);
  //   console.log("item_id", item_id);
  //   // context.message_id = uuidv4();
  //   const contextdata = {
  //     order_id: OrderId,
  //     item_id,
  //     status:"Cancelled"
  //   };
  //   console.log("contaxtdata", contextdata);
  //   try {
  //     const { data } = await axios({
  //       // url: `https://ondc.hulsecure.in/preprod/v1/cancel-item`,
  //       url: `https://localhost:8092/preprod/v1/cancel-item`,
  //       method: "PUT",
  //       data: contextdata,
  //     });
  //     console.log("sas => ", data);
  //     toast.success("Status updated by seller app");
  //     console.log("data>>>>>>", data);
  //   } catch (error: any) {
  //     console.log("error", error);
  //     toast.error(`Error : ${error.message}`);
  //   }
  // };

  interface Orders {
    index: number;
    ondcorderid: number;
    status: string;
    // response: any;
  }

  // const cancelOrders:Orders[] = orderList;

  const cancelOrders = orderList.filter((order) => order.status == `${value}`);
  // const cancelOrder = (orderList as Orders[]).filter(order => order.status == 'cancelled');

  // console.log("cancelOrder>153", cancelOrders);
  // console.log("orderList>154", orderList);

  // const getUpdates = async () => {
  //   // alert();
  //   try {
  //     const { data } = await axios({
  //       url: `/orders/on_update`,
  //       method: "GET",
  //     });
  //     // console.log("UpdateData=> 217", data);
  //     if (data.status === 200) {
  //       // alert("status updating");
  //       // console.log("UpdateData=> 220", data.data);
  //     } else {
  //       console.log("error222", data);
  //     }
  //     setloading(false);
  //     setTimeout(() => {
  //       $("#dataTable").DataTable();
  //     }, 1000);
  //     // console.log("UpdateData=> 227", data);
  //   } catch (error) {
  //     setloading(false);
  //   }
  // };
  // useEffect(() => {
  //   getUpdates();
  // }, []);

  const getUpdateList = async () => {
    setlistloading(true);
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseUrl}/orders/getupdatelogs`,
        method: "GET",
        headers: {
          Authorization: ` ${token}`
        },
      });
      const uniqueData: any = [];
      const seenKeys = new Set();
      data.forEach((item: any) => {
        const key = item?.body?.message?.order.id || JSON.stringify(item?.body?.message?.order.id);
        console.log("item", item)
        if (!seenKeys.has(key)) {
          seenKeys.add(key);
          uniqueData.push(item);
        }
      });
      uniqueData.reverse();
      setupdateList(uniqueData);
      setFilteredData(uniqueData);
      setlistloading(false);
    } catch (error) {
      setlistloading(false);
      setError("error");
    }
  };

  useEffect(() => {
    getUpdateList();
  }, []);

  const handledata = async (orderID: any, itemID: any, context: any, itemCount: any, provider: any) => {
    setPopup(!popup);
    setreturncontext(context);
    setreturnitemcount(itemCount);
    setreturnprovider(provider);
    try {
      const { data } = await axios({
        url: `${baseUrl}/orders/itemupdatedetails`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          order_id: orderID,
          item_id: itemID,
        },
      });
      if (data.status === 200) {
        setOrderpopup(data.data.reverse());
        setpopupFilter(data.data.reverse());
      } else {
        alert("404 not found !");
      }
      setloading(false);
      // setTimeout(() => {
      //   $("#dataTable").DataTable();
      // }, 1000);
      // console.log("data>>>>>>", data);
    } catch (error) {
      setloading(false);
    }
    const closePopup = () => {
      setPopup(false);
    };
  };

  // 
  const filterBySearch = (event: any) => {
    const querySearch = event.target.value.toLowerCase();
    let filteredItems = updateList.filter((item: any) => {
      const orderId = item?.body?.message?.order?.id;
      const status = item?.status;
      return (
        (orderId && orderId.toLowerCase().includes(querySearch)) ||
        (status && status.toLowerCase().includes(querySearch))
      );
    });
    setFilteredData(filteredItems);
  };
  const showModal = (src: any) => {
    setImageSrc(src);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // popupFilter
  const popupFilterSearch = (event: any) => {
    const querySearch = event.target.value.toLowerCase();
    let filteredPopup = orderpopup.filter((item: any) => {
      const orderId = item?.ondcorderid;
      const shopifyOrderId = item?.shopifyorderid;
      const status = item?.status;
      const price = item?.price;
      const itemId = item?.itemid;
      return (
        (shopifyOrderId && shopifyOrderId.toLowerCase().includes(querySearch)) ||
        (orderId && orderId.toLowerCase().includes(querySearch)) ||
        (status && status.toLowerCase().includes(querySearch)) ||
        (price && price.toLowerCase().includes(querySearch)) ||
        (itemId && itemId.toLowerCase().includes(querySearch))
      );
    });
    setpopupFilter(filteredPopup);
  };
  // 
  const reasonCodeMap: any = {
    "001": "Buyer does not want product any more",
    "002": "Product available at lower than order price",
    "003": "Product is of incorrect quantity or size",
    "005": "Product delivered is different from what was shown and ordered",
  };
  const COLUMNS = [
    {
      Header: "Sn.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "order id	",
      accessor: (item: any) => (`${item?.body?.message?.order?.id}`),
      sticky: "left"
    },
    {
      Header: "Delivered Date",
      accessor: (item: any) => (item?.createdAt ? moment(item?.createdAt).format('YYYY-MM-DD HH:mm'): null),
      sticky: "left"
    },
    {
      Header: "Item Id",
      accessor: (item: any) => (
        <div className="nestedTable">
          <table className="w-100 table-bordered">
            <thead>
              <tr>
                <th>SN</th>
                <th>Item ID</th>
                <th>Image</th>
                <th>Reason</th>
                <th>Update Type</th>
                <th>Quantity Count</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {item?.body?.message?.order?.fulfillments &&
                item?.body?.message?.order?.fulfillments?.length > 0 ? (
                item?.body?.message?.order?.fulfillments.map(
                  (fulfillment: any, i: number) => {
                    const tags = fulfillment?.tags[0]?.list || [];
                    const getTagValue = (code: string) => {
                      const tag = tags.find((t: any) => t.code === code);
                      return tag ? tag.value : "N/A";
                    };
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{getTagValue("item_id")}</td>
                        <div>
                          <td>
                            <img
                              src={getTagValue("images")}
                              alt="Image"
                              style={{ width: "100px", height: "100px", cursor: "pointer" }}
                              onClick={() => showModal(getTagValue("images"))}
                            />
                          </td>

                          <AntModal
                            title="Image Preview"
                            visible={isModalVisible}
                            onCancel={handleCancel}
                            footer={null}
                          >
                            <img src={imageSrc} alt="Full Image" style={{ width: '100%', height: 'auto' }} />
                          </AntModal>
                        </div>
                        <td>{reasonCodeMap[getTagValue("reason_id")]}</td>
                        <td>{fulfillment.type}</td>
                        <td>{getTagValue("item_quantity")}</td>
                        <td>
                          <button
                            className="btn btn-info btn-sm"
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => handledata(
                              item?.body?.message?.order?.id,
                              getTagValue("item_id"),
                              item?.body?.context,
                              getTagValue("item_quantity"),
                              item?.body?.message?.order?.provider?.id
                            )}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  }
                )
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No fulfillments available
                  </td>
                </tr>
              )}
            </tbody>
          </table>

        </div>
      ),
      sticky: "left"
    },
  ]



  const SUBCOLUMNS = [
    {
      Header: "Sn.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    // {
    //   Header: "OpenCart OrderId",
    //   accessor: (item: any) => (item?.shopifyorderid ? item?.shopifyorderid : null),
    //   // accessor: (item: any) => (item?.sku ? item?.sku : null),
    //   sticky: "left"
    // },
    {
      Header: "ONDC OrderId",
      accessor: (item: any) => (item?.ondcorderid ? item?.ondcorderid : null),
      sticky: "left"
    },
    {
      Header: "Item Id",
      accessor: (item: any) => (item?.itemid ? item?.itemid : null),
      sticky: "left"
    },
    {
      Header: "Cancellation reason /retrun reason(Network description)",
      accessor: (item: any) => (item?.return_reson ? reasonCodeMap[item?.return_reson] : null),
      sticky: "left"
    },
    {
      Header: "Cancelled At Date & time",
      accessor: (item: any) => (item?.cancel_date ? item?.cancel_date : null),
      sticky: "left"
    },
    {
      Header: "Status",
      accessor: (item: any) => (item?.status ? item?.status : null),
      sticky: "left"
    },
    {
      Header: "Action",
      accessor: (item: any) => (
        <div className="">
          <div>
            <select
              className="btn btn-secondary btn-sm"
              // value={status}
              // onChange={StatusChange}
              value={item.status}
              onChange={(e) =>
                changeReturnStatus(item, e.target.value)
              }
            >
              <option value="Return_Initiated">Return_Initiated</option>
              <option value="Return_Approved">Return_Approved</option>
              <option value="Return_Rejected">Return_Rejected</option>
              <option value="Return_Picked">Return_Picked</option>
              <option value="Return_Delivered">Return_Delivered</option>
              <option value="Liquidated">Liquidated</option>
              <option value="Cancelled">Cancelled</option>
            </select>
            <p className="d-none">{`You selected : ${status}`}</p>
          </div>
          <button
            className="btn btn-primary d-flex mx-auto btn-sm my-2"
            type="submit"
            onClick={() =>
              changeStatus(item?.ondcorderid, item.itemid, item?.return_reson)
            }
          >
            Update
          </button>
          {/* <button
          className="btn btn-outline-danger d-flex mt-3 mx-auto"
          type="submit"
          onClick={() =>
            CancelItem(item?.ondcorderid, item.itemid)
          }
        >
          Cancel Item
        </button> */}
        </div>
      ),
      sticky: "left"
    },
  ]


  return (
    <>
      <ThemeMaker>
        <div>
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog ">
              <div className="modal-content container">
                <div className="modal-header">
                  <h6 className="m-0 font-weight-bold text-danger">Orders Details</h6>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row justify-content-between">
                    <div className="col-md-4 col-6 mb-2">
                      <label htmlFor="start-date">Search Filter : </label>
                      <input type="search" className="form-control" placeholder="Search" id="start-date" onChange={popupFilterSearch} />
                    </div>
                  </div>
                  <div className="table-responsive table-bordered">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Paginated data={popupFilter} columns={SUBCOLUMNS} />
                    )}
                  </div>
                  {/* <table className="table table-bordered"
                    id="dataTable"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Sn</th>
                        <th>OpenCart OrderId</th>
                        <th>ONDC OrderId</th>
                        <th>Item Id</th>
                        <th>
                          Cancellation reason /retrun reason(Network
                          description)
                        </th>
                        <th>Cancelled At Date & time</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tfoot></tfoot>
                    <tbody>
                      {orderpopup.map((item: any, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>

                          <td>{item?.shopifyorderid}</td>
                          <td>{item?.ondcorderid}</td>
                          <td>{item?.itemid}</td>
                          <td>{item?.cancellation_reason}</td>
                          <td>{item?.cancel_date}</td>
                          <td>{item?.status}</td>
                          <td>
                            <div>
                              <select
                                className="btn btn-secondary"
                                value={item.status}
                                onChange={(e) =>
                                  changeReturnStatus(item, e.target.value)
                                }
                              >
                                <option value="Return_Initiated">
                                  Return_Initiated
                                </option>
                                <option value="Return_Approved">
                                  Return_Approved
                                </option>
                                <option value="Return_Rejected">
                                  Return_Rejected
                                </option>
                                <option value="Return_Picked">
                                  Return_Picked
                                </option>
                                <option value="Return_Delivered">
                                  Return_Delivered
                                </option>
                                <option value="Liquidated">Liquidated</option>
                                <option value="Cancelled">Cancelled</option>
                              </select>
                              <p className="d-none">{`You selected : ${status}`}</p>
                            </div>
                            <button
                              className="btn btn-primary d-flex mx-auto my-3"
                              type="submit"
                              onClick={() =>
                                changeStatus(item?.ondcorderid, item.itemid)
                              }
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>
                <div className="modal-footer">
                  <button type="button"
                    className="btn btn-default"
                    data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-none">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3"></div>
              <div className="d-flex justify-content-between align-items-center m-2">
                <select className="btn btn-outline-primary" value={value} onChange={handleChange}>
                  <option value="In-progress">In-progress</option>
                  <option value="false">False</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Completed">Completed</option>
                  <option value="Created">Created</option>
                </select>
                <p>{`You selected : ${value}`}</p>
              </div>

              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>ID</th>
                    <th>Status</th>
                    {/* <th>Delivered Date</th> */}
                  </tr>
                </thead>
                <tbody>
                  {cancelOrders &&
                    cancelOrders.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.ondcorderid}</td>
                          <td> {item.status}</td>
                          {/* <td> {item.response.message.order.fulfillment[0].end.timestamp}</td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4 mt-1">
              <div className="card-header py-3">
                <div className="row">
                  <h6 className="m-0 font-weight-bold text-danger">
                    Cancel & Return List
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-4 col-6">
                    <label htmlFor="start-date">Search Filter : </label>
                    <input type="search" className="form-control" placeholder="Search" id="start-date" onChange={filterBySearch} />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-bordered">
                  {listloading ? (
                    <Loader />
                  ) : (
                    <Paginated data={FilteredData} columns={COLUMNS} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default CancelReturn;
