import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { format } from 'date-fns'
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../orderlist/orderlistPopus.css";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
// import { BasicTable } from '../../components/Table';
import { Paginated } from "../../components/Paginated";
import '../../components/table.css';
import Order from "../order";
import config from "../../config/config";
import moment from "moment";
// import CircularProgress from '@mui/material/CircularProgress';
import * as XLSX from 'xlsx';
var $ = require("jquery");

const OrderList = () => {
  const { baseUrl } = config;
  // filter
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const [popup, setPopup] = useState(false);
  const [orderStatusPopup, setOrderStatusPopup] = useState(false);
  const [orderFulfillmentPopup, setOrderFulfillmentPopup] = useState(false);
  const [orderPopup, setOrderPopup] = useState([]);

  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  // console.log("decoded.data.permission.user",decoded.data.permission.user);
  // const { user, product, order } = decoded.data.permission;
  const { data } = decoded;
  const { isAdmin, permission: { product, user, order, support, order_manage, igm }, } = data;

  const history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const [downloadcsv, setDownloadcsv] = useState({});
  const [loading, setloading] = useState(true);
  const closeModel: any = useRef();
  const [error, setError] = useState("");
  const [_id, setId] = useState("");

  const [fullFillmentStatus, setFullFillmentStatus] = useState("");
  const [status, setstatus] = useState();
  const [orderid, setorderid] = useState("");
  const [dynamicValue, setDynamicValue] = useState("In-progress");
  const [context, setcontext] = useState("");

  const handleClickPop = async (id: any) => {
    setPopup(!popup);
    try {
      const { data } = await axios({
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          order_id: id,
        },
      });
      // console.log("sas => ", data);
      if (data.status === 200) {
        setOrderPopup(data.data.reverse());
      } else {
        toast.error("404 not found !");
      }
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
      // console.log("data>>>>>>", data);
    } catch (error) {
      setloading(false);
    }
  };
  const closePopup = () => {
    setPopup(false);
  };

  const getOrderList = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseUrl}/orders/npdata`,
        method: "GET",
        headers: {
          Authorization: ` ${token}`
        },
      });
      setOrderList(data.reverse());
      setFilteredData(data.reverse());
      setloading(false);
    } catch (error) {
      setloading(false);
      setError("error");
    }
  };
  const reasonCodeMap: any = {
    "001": "Price of one or more items have changed due to which buyer was asked to make additional payment",
    "002": "One or more items in the Order not available",
    "003": "Product available at lower than order price",
    "005": "Merchant rejected the order",
    "006": "Order / fulfillment not received as per buyer app TAT SLA",
    "007": "Order / fulfillment not received as per buyer app TAT SLA",
    "008": "Order not ready for pickup",
    "009": "Wrong product delivered",
    "010": "Buyer wants to modify address / other order details",
    "011": "Buyer not found or cannot be contacted",
    "013": "Buyer refused to accept delivery",
    "014": "Address not found",
    "015": "Buyer not available at location",
    "016": "Accident / rain / strike / vehicle issues",
    "017": "Order delivery delayed or not possible",
    "018": "Delivery pin code not serviceable",
    "019": "Pickup pin code not serviceable",
    "020": "Order lost in transit",
    "021": "Packed order not complete",
  };
  const exportToCSV = (orderList: any) => {
    const headers = [
      'S.No',
      'Buyer NP Name',
      'Seller NP Name',
      'Order Create Date & Time',
      'Network Order Id	',
      'Network Transaction Id',
      'Seller NP Order Id',
      'Item Id',
      'Qty',
      'Seller NP Type (MSN / ISN)',
      'Order Status',
      'Name of Seller',
      'Seller Pincode',
      'Seller City',
      'SKU Name',
      'SKU Code',
      'Order Category (F&B/ Grocery/ Home & Decor)',
      'Ready to Ship At Date & Time',
      'Shipped At Date & Time',
      'Delivered At Date & Time	',
      'Delivery Type (On-network/ Off-network)',
      'Logistics Seller NP Name (For on-network delivery)',
      'Logistics Network Order Id (For on-network delivery)',
      'Logistics Network Transaction Id (For on-network delivery)',
      'Delivery City',
      'Delivery Pincode',
      'Cancelled At Date & Time	',
      'Cancelled By (Buyer/Seller)',
      'Cancelleation Reason',
      'Total Shipping Charges',
      'Total Order Value',
      'Total Refund Amount',
    ];
    // 
    const data = orderList.map((item: any, i: any) => {
      const setReason = reasonCodeMap[item?.cancellation_reason_id] || '';
      const createAt = formattedCreateAt(item?.createdAt);
      const deliveredDate = formattedCreateAt(item?.delivered_date);
      const readyShipped_time = formattedCreateAt(item?.ready_shipped_time);
      const shippedTime = formattedCreateAt(item?.shipped_time);
      const cancelDate = formattedCreateAt(item?.cancel_date);
      return [
        i + 1,
        item?.response?.context?.bap_id,
        'Redtape',
        createAt,
        item?.ondcorderid,
        item?.response?.context?.transaction_id,
        item?.ondcorderid,
        item?.response?.message?.order?.items[0]?.fulfillment_id,
        item?.response?.message?.order?.items[0]?.quantity?.count,
        'ISN',
        item?.status,
        'Redtape',
        '560061',
        'Bengaluru',
        item?.sku,
        item?.sku,
        'Fashion',
        readyShipped_time,
        shippedTime,
        deliveredDate,
        'Off-Network',
        '',
        '',
        '',
        item?.response?.message?.order?.billing?.address?.city,
        item?.response?.message?.order?.billing?.address?.area_code,
        cancelDate,
        item?.cancelled_by ? item?.cancelled_by : null,
        setReason,
        '',
        item?.response?.message?.order?.quote?.price?.value,
        '',
      ].join(',');
    });

    const csvContent = [headers.join(','), ...data].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `orders_${new Date().getTime()}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  const formattedCreateAt = (createAt: any) =>
    createAt ? moment(createAt).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '';

  // const exportToExcel = (orderList: any) => {
  //   const headers = [
  //     'S.No',
  //     'Buyer NP Name',
  //     'Seller NP Name',
  //     'Order Create Date & Time',
  //     'Network Order Id	',
  //     'Network Transaction Id',
  //     'Seller NP Order Id',
  //     'Item Id',
  //     'Qty',
  //     'Seller NP Type (MSN / ISN)',
  //     'Order Status',
  //     'Name of Seller',
  //     'Seller Pincode',
  //     'Seller City',
  //     'SKU Name',
  //     'SKU Code',
  //     'Order Category (F&B/ Grocery/ Home & Decor)',
  //     'Ready to Ship At Date & Time',
  //     'Shipped At Date & Time',
  //     'Delivered At Date & Time	',
  //     'Delivery Type (On-network/ Off-network)',
  //     'Logistics Seller NP Name (For on-network delivery)',
  //     'Logistics Network Order Id (For on-network delivery)',
  //     'Logistics Network Transaction Id (For on-network delivery)',
  //     'Delivery City',
  //     'Delivery Pincode',
  //     'Cancelled At Date & Time	',
  //     'Cancelled By (Buyer/Seller)',
  //     'Cancelleation Reason',
  //     'Total Shipping Charges',
  //     'Total Order Value',
  //     'Total Refund Amount',
  //   ];

  //   const data = orderList.map((item: any, i: any) => {
  //     const createAt = formattedCreateAt(item?.createdAt);
  //     const deliveredDate = formattedCreateAt(item?.delivered_date);
  //     const readyShipped_time = formattedCreateAt(item?.ready_shipped_time);
  //     const shippedTime = formattedCreateAt(item?.shipped_time);
  //     const cancelDate = formattedCreateAt(item?.cancel_date);
  //     return [
  //       i + 1,
  //       item?.response?.context?.bap_id,
  //       'Redtape',
  //       createAt,
  //       item?.ondcorderid,
  //       item?.response?.context?.transaction_id,
  //       item?.ondcorderid,
  //       item?.response?.message?.order?.items[0]?.fulfillment_id,
  //       item?.response?.message?.order?.items[0]?.quantity?.count,
  //       'ISN',
  //       item?.status,
  //       'Redtape',
  //       '560061',
  //       'Bengaluru',
  //       item?.sku,
  //       item?.sku,
  //       '',
  //       '',
  //       readyShipped_time,
  //       shippedTime,
  //       deliveredDate,
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       cancelDate,
  //       '',
  //       item?.cancellation_reason_id,
  //       '',
  //       item?.response?.message?.order?.quote?.price?.value,
  //       '',
  //     ];
  //   });
  //   // 
  //   const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Orders');
  //   const fileName = `orders_${new Date().getTime()}.xlsx`;
  //   XLSX.writeFile(wb, fileName);
  // }; 

  // Filter

  const handleStartDateChange = (event: any) => {
    setStartDate(event.target.value);
    filterData(event.target.value, endDate);
  };

  const handleEndDateChange = (event: any) => {
    setEndDate(event.target.value);
    filterData(startDate, event.target.value);
  };
  // filter By Dates
  const filterData = (start: any, end: any) => {
    const startFilter = start ? new Date(start) : null;
    const endFilter = end ? new Date(end) : null;
    const filtered = orderList.filter((item: any) => {
      const itemDate = new Date(item.createdAt);
      return (
        (!startFilter || itemDate >= startFilter) && (!endFilter || itemDate <= endFilter)
      );
    });
    setFilteredData(filtered);
  };

  const filterBySearch = (event: any) => {
    const querySearch = event.target.value;
    let SearchFiltere = orderList.filter((item: any) => {
      return (
        item?.ondcorderid.toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.status.toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.ondcorderid?.toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.message?.order?.items[0]?.fulfillment_id?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response.message.order.quote.breakup[0].title?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.message?.order?.billing?.address?.area_code?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.message?.order?.payment?.status?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.context?.bap_id?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.delivery_city?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.shopifyorderef?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.sku?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        `${item?.response?.message?.order.billing.name.slice(0, 4)}-${item?.response?.message?.order?.billing?.phone?.slice(-5)}`.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.context?.transaction_id?.toString().toLowerCase().includes(querySearch.toLowerCase())
      );
    });
    setFilteredData(SearchFiltere);
  };
  // filter End
  const DownloadCsv = async () => {
    // console.log("file download")
    try {
      const {
        data: { data },
      } = await axios({
        url: "/orders/exportcsv",
        method: "GET",
        headers: {
          "Content-Type": "application/csv",
          Authorization: ` ${token}`
        },
      });

      // console.log(data);
      setDownloadcsv(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderList();
    //  DownloadCsv();
  }, []);

  useEffect(() => {
    if (status === "Accepted") {
      setFullFillmentStatus("Pending");
    }
    if (status === "In-progress") {
      setFullFillmentStatus("Order-picked-up");
    }
    if (status === "Completed") {
      setFullFillmentStatus("Order-delivered");
    }
    if (status === "Cancelled") {
      setFullFillmentStatus("Cancelled");
    }
  }, [status]);

  const changeStatus = async () => {
    try {
      const { data } = await axios({
        url: `/orders/orderstatus`,
        method: "PUT",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          order_id: orderid,
          orderStatus: status,
          fullFillmentStatus: fullFillmentStatus,
        },
      });
      if (data.status === 200) {
        toast.success("Order status updated !");
        setOrderStatusPopup(false);
        changeStatusseller(orderid, context);
      } else {
        toast.error("404 not found !");
      }
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) { }
  };

  const CancelItem = async (OrderId: any, item_id: any) => {
    // context.message_id = uuidv4();
    const contextdata = {
      order_id: OrderId,
      item_id,
      status: "Cancelled",
    };
    try {
      const { data } = await axios({
        url: `/orders/cancel-item`,
        method: "PUT",
        headers: {
          Authorization: ` ${token}`
        },
        data: contextdata,
      });
      // console.log("sas => ", data);
      toast.success("Status updated by seller app");
      // console.log("data>>>>>>", data);
    } catch (error: any) {
      // console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  const changeStatusseller = async (orderids: any, context: any) => {
    context.message_id = uuidv4();
    const contextdata = {
      context,
      message: {
        order_id: orderids,
      },
    };
    // console.log("contaxtdata 188", contextdata);
    try {
      const { data } = await axios({
        url: `/preprod/v1/status`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: contextdata,
      });
      // console.log("sas => ", data);
      toast.success("unsolicited Status updated by seller app");
      // console.log("data>>>>>>", data);
    } catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  const ChangeStatusPopup = (
    ondcorderid: any,
    orderStatus: any,
    context: any
  ) => {
    // console.log(">>>>>>>>>>", orderStatus);
    setOrderStatusPopup(true);
    setorderid(ondcorderid);
    setstatus(orderStatus);
    setcontext(context);
    setDynamicValue(orderStatus);
  };

  const closePop = () => {
    setOrderStatusPopup(false);
  };

  const CancelOrder = async (shopifyorderid: any, ondcorderid: any) => {
    // console.log("ondcorderid", ondcorderid);
    // console.log("shopifyorderid", shopifyorderid);
    const confirmed = window.confirm(
      "Are you sure you want to cancel this order?"
    );
    if (confirmed) {
      try {
        const { data } = await axios({
          url: `/orders/cancelorder`,
          method: "POST",
          data: {
            shopifyorderid: shopifyorderid,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: ` ${token}`
          },
        });
        // console.log("sas => ", data);
        if (data.status === 200) {
          toast.success("Order Cancelled");
        } else {
          toast.error("404 not found!");
        }
        // console.log("data>>>>>>", data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const COLUMNS = [
    {
      Header: "Sn.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Buyer NP Name",
      accessor: (item: any) => (item?.response?.context?.bap_id ? item?.response?.context?.bap_id : null),
      sticky: "left"
    },
    {
      Header: "Seller NP Name",
      accessor: (a: any) => (a ? "Redtape" : ""),
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Order Create Date & Time",
      accessor: (a: any) => a?.createdAt ? moment(a.createdAt).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : null,
      sticky: "left"
    },
    {
      Header: "Network Order Id	",
      accessor: (item: any) => (item?.ondcorderid ? item?.ondcorderid : null),
      sticky: "left"
    },
    {
      Header: "Network Transaction Id",
      // accessor: (item: any) => (`${item?.response?.context?.transaction_id}`),
      accessor: (item: any) => (item?.response?.context?.transaction_id ? item?.response?.context?.transaction_id : null),
      sticky: "left"
    },
    {
      Header: "Seller NP Order Id",
      accessor: (item: any) => (item?.ondcorderid ? item?.ondcorderid : null),
      sticky: "left"
    },
    {
      Header: "Item Id",
      accessor: (item: any) => (`${item?.response?.message?.order?.items[0]?.fulfillment_id}`),
      sticky: "left"
    }, {
      Header: "Qty	",
      accessor: (item: any) => (`${item?.response?.message?.order?.items[0]?.quantity?.count}`),
      // accessor: (a: any) => (a ? "2" : ""),
      sticky: "left"
    },
    {
      Header: "Seller NP Type (MSN / ISN)",
      accessor: (a: any) => (a ? "ISN" : ""),
      // accessor: (item: any) => (`${item?.response?.context?.transaction_id}`),
      sticky: "left"
    },
    {
      Header: "Order Status",
      accessor: (item: any) => (item?.status ? item?.status : null),
      sticky: "left"
    },
    {
      Header: "Name of Seller",
      // accessor: (item: any) => (`${item?.response?.context?.transaction_id}`),
      accessor: (a: any) => (a ? "Redtape" : ""),
      sticky: "left"
    },
    {
      Header: "Seller Pincode",
      accessor: (a: any) => (a ? "560061" : ""),
      // accessor: (item: any) => (`${item?.response?.context?.transaction_id}`),
      sticky: "left"
    },
    {
      Header: "Seller City	",
      accessor: (a: any) => (a ? "Bengaluru" : ""),
      sticky: "left"
    },
    {
      Header: "SKU Name",
      accessor: (item: any) => (item?.sku ? item?.sku : null),
      sticky: "left"
    },
    {
      Header: "SKU Code	",
      accessor: (item: any) => (item?.sku ? item?.sku : null),
      sticky: "left"
    },
    {
      Header: "Order Category (F&B/ Grocery/ Home & Decor)	",
      accessor: (a: any) => (a ? "Fashion" : ""),
      // accessor: (item: any) => (`${item?.response?.context?.transaction_id}`),
      sticky: "left"
    },
    {
      Header: "Ready to Ship At Date & Time	",
      accessor: (a: any) => a?.ready_shipped_time ? moment(a.ready_shipped_time).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : null,
      sticky: "left"
    },
    {
      Header: "Shipped At Date & Time	",
      accessor: (a: any) => a?.shipped_time ? moment(a.shipped_time).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : null,
      sticky: "left"
    },
    {
      Header: "Delivered At Date & Time	",
      accessor: (a: any) => a?.delivered_date ? moment(a.delivered_date).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : null,
      sticky: "left"
    }, {
      Header: "Delivery Type (On-network/ Off-network)	",
      accessor: (a: any) => (a ? "Off Network	" : ""),
      sticky: "left"
    },
    {
      Header: "Logistics Seller NP Name (For on-network delivery)	",
      accessor: (a: any) => (a ? "..." : ""),
      sticky: "left"
    },
    {
      Header: "Logistics Network Order Id (For on-network delivery)	",
      accessor: (a: any) => (a ? "..." : ""),
      sticky: "left"
    },
    {
      Header: "Logistics Network Transaction Id (For on-network delivery)	",
      accessor: (a: any) => (a ? "..." : ""),
      sticky: "left"
    },
    {
      Header: "Delivery City	",
      // accessor: (item: any) => (`${item?.response?.context?.transaction_id}`),
      accessor: (item: any) => (`${item?.response?.message?.order?.billing?.address?.city}`),
      sticky: "left"
    },
    {
      Header: "Delivery Pincode	",
      accessor: (item: any) => (`${item?.response?.message?.order?.billing?.address?.area_code}`),
      sticky: "left"
    },
    {
      Header: "Cancelled At Date & Time	",
      accessor: (a: any) => a?.cancel_date ? moment(a.cancel_date).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : null,
      sticky: "left"
    },
    {
      Header: "Cancelled By (Buyer/Seller)	",
      accessor: (a: any) => (a.cancelled_by ? a.cancelled_by : null),
      sticky: "left"
    },
    {
      Header: "Cancelleation Reason	",
      accessor: (a: any) => a?.cancellation_reason_id ? reasonCodeMap[a?.cancellation_reason_id] : null,
      sticky: "left"
    },
    {
      Header: "Total Shipping Charges	",
      accessor: (a: any) => (a ? "..." : ""),
      sticky: "left"
    },
    {
      Header: "Total Order Value	",
      accessor: (item: any) => (`${item?.response?.message?.order?.quote?.price?.value}`),
      sticky: "left"
    },
    {
      Header: "Total Refund Amount",
      accessor: (a: any) => (a ? "..." : ""),
      sticky: "left"
    },
  ];

  return (
    <>
      <ThemeMaker>
        {/* <Order /> */}
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4 mt-1">
              <div className="card-header py-3">
                <div className="row d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-danger">
                    Order List
                  </h6>

                  <div className="downloadCsv">
                    {isAdmin === "true" ? (
                      <button className="btn btn-danger" onClick={() => exportToCSV(orderList)}>Download Sheet</button>
                    ) : null}
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-md-4 col-6">
                      <label htmlFor="start-date">Search Filter : </label>
                      <input type="search" className="form-control" placeholder="Search" id="start-date" onChange={filterBySearch} />
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div className="col-6">
                      <label htmlFor="start-date"> Start Date : </label>
                      <input type="date" className="form-control" placeholder="Select a date" id="start-date"
                        value={startDate}
                        onChange={handleStartDateChange} />
                    </div>
                    <div className="col-5">
                      <label htmlFor="end-date"> End Date : </label>
                      <input type="date" className="form-control" value={endDate}
                        onChange={handleEndDateChange} id="end-date" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-bordered">
                  {loading ? (
                    <Loader />
                  ) : (
                    <Paginated data={filteredData.reverse()} columns={COLUMNS} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog ">
              <div className="modal-content container">
                <div className="modal-header">
                  <h2>Orders Details</h2>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">
                  <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Sn</th>
                        <th>Shopify OrderId</th>
                        <th>ONDC OrderId</th>
                        <th>Item Id</th>
                        <th>F_Code</th>
                        <th>F_Name</th>
                        <th>
                          Cancellation reason /retrun reason(Network
                          description)
                        </th>
                        <th>Cancelled At Date & time</th>
                        <th>Cancelled_by</th>
                        <th>Cancellation_reason_id</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tfoot></tfoot>
                    <tbody>
                      {orderPopup.map((item: any, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>

                          <td>{item?.shopifyorderid}</td>
                          <td>{item?.ondcorderid}</td>
                          <td>{item?.itemid}</td>
                          <td>{item?.f_code}</td>
                          <td>{item?.f_name}</td>
                          <td>{item?.cancellation_reason}</td>
                          <td>{item?.cancel_date}</td>
                          <td>{item?.cancelled_by}</td>
                          <td>{item?.cancellation_reason_id}</td>
                          <td>{item?.status}</td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {orderStatusPopup ? (
          <div className="orderStatuspopup text-center mx-auto p-5 border shadow bg-light position-fixed top-50 end-0 translate-middle-y">
            <div>
              <div className="selectStatus">
                <p>{`Update order : ${status}`}</p>
                <select
                  className="btn btn-secondary"
                  value={status}
                  onChange={(event: any) => {
                    // console.log("event", event);
                    setstatus(event.target.value);
                  }}
                >
                  <option value="Created" disabled={dynamicValue !== "Created"}>
                    Created
                  </option>
                  <option
                    value="Accepted"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "Accepted" &&
                      (dynamicValue === "In-progress" ||
                        dynamicValue === "Completed")
                    }
                  >
                    Accepted
                  </option>
                  <option
                    value="In-progress"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "In-progress"
                    }
                  >
                    In-progress
                  </option>
                  <option
                    value="Completed"
                    disabled={
                      dynamicValue !== "Accepted" &&
                      dynamicValue === "Completed"
                    }
                  >
                    Completed
                  </option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              <div className="selectStatus">
                <p className="m-2">{`Update fulfillment : ${fullFillmentStatus}`}</p>
                <select
                  className="btn btn-secondary"
                  value={fullFillmentStatus}
                  onChange={(event: any) => {
                    // console.log("event", event);
                    setFullFillmentStatus(event.target.value);
                  }}
                >
                  <option value="Pending" disabled={status !== "Accepted"}>
                    Pending
                  </option>
                  <option
                    value="Order-picked-up"
                    disabled={status !== "In-progress"}
                  >
                    Order picked up
                  </option>
                  <option
                    value="Out-for-delivery"
                    disabled={status !== "In-progress"}
                  >
                    Out for delivery
                  </option>
                  <option
                    value="Order-delivered"
                    disabled={status !== "Completed"}
                  >
                    Order delivered
                  </option>
                  <option value="Cancelled" disabled={status !== "Cancelled"}>
                    Cancelled
                  </option>
                </select>
              </div>
            </div>
            <button
              className="btn btn-primary d-flex m-auto updateButton"
              type="submit"
              onClick={changeStatus}
            >
              Update
            </button>
            <button
              className="border-0 px-3 py-2 rounded-circle "
              onClick={closePop}
            >
              x
            </button>
          </div>
        ) : null}
      </ThemeMaker>
    </>
  );
};

export default OrderList;
