import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import PageNotFound from "../pages/404";
import Login from "../pages/login";
import AddUser from "../pages/adduser";
import UserList from "../pages/userlist";
import Profile from "../pages/profile";
import ProductList from "../pages/productlist";
import AddProduct from "../pages/addproduct";
import LoginPageIsLogedIn from "../components/loginProtected";
import ProtectedRoute from "../components/protectedRoute";
import ForgetPassword from "../pages/forget-password";
import ResetPassword from "../pages/reset-password";
import SearchKeyword from "../pages/searchKeyword";
import SearchLog from "../pages/searchLog"
import TxnIdLogs from "../pages/TxnIdLogs";
import SupportList from "../pages/support";
import CancelReturn from "../pages/cancelreturn";
import OrderList from "../pages/orderlist";
import ManageOrderList from '../pages/manage_orderlist';
import SellerDetail from "../pages/shopDetails";
import OrderCompleteDetails from "../pages/order/orderCompleteDetail";
import OrderCompleteinfo from "../pages/order/orderCompleteinfo";
import IssuesList from "../pages/igm_protal/issuesList";
import OnIssue from "../pages/igm_protal/onIssue";

const routes = [
  {
    path: "/",
    component: <Dashboard />
  },
  {
    path: "/login",
    component: <Login />
  },
  {
    path: "/adduser",
    component: <AddUser />
  },
  {
    path: "/userlist",
    component: <UserList />
  },
  {
    path: "/profile",
    component: <Profile />
  },
  {
    path: "/addproduct",
    component: <AddProduct />
  },
  {
    path: "/productlist",
    component: <ProductList />
  },
  {
    path: "/user/:id",
    component: <AddUser />
  },
  {
    path: "/product/:id",
    component: <AddProduct />
  },
  {
    path:"/forget-password",
    component: <ForgetPassword />
  },
  {
    path:"/reset-password/:id",
    component: <ResetPassword />
  },
  {
    path:"/searchkeyword",
    component: <SearchKeyword />
  },

  {
    path:"/searchlog",
    component: <SearchLog />
  },
  {
    path: "/supportlist",
    component: <SupportList />
  },
  {
    path:"/search_txnlog",
    component: <TxnIdLogs />
  },
  {
    path: "/cancelreturn",
    component: <CancelReturn />,
  },
  {
    path: "/orderlist",
    component: <OrderList />
  },
  {
    path: "/manage_orders",
    component: <ManageOrderList/>
  },
  {
    path:"/issueslist",
    component: <IssuesList />
  },
  {
    path:"/onissue",
    component: <OnIssue />
  },
  {
    path:"/sellerdetail",
    component: <SellerDetail />
  },
  {
    path:"/orderCompleteDetail/:id?",
    component: <OrderCompleteDetails />
  },
  {
    path:"/orderCompleteinfo/:id?",
    component: <OrderCompleteinfo />
  },
  {
    path: "*",
    component: <PageNotFound />
  }
]

const AllRoutes = () => {
  return (
    <>
      <Router>
        <Switch>
          {
            routes.map((item, index) => (
              <Route exact path={item?.path} key={index} >
                {
                  item.path === '/login' ? (
                    <LoginPageIsLogedIn>
                      {item.component}
                    </LoginPageIsLogedIn>
                  ) : (
                    <>
                      <ProtectedRoute>
                        {item.component}
                      </ProtectedRoute>
                    </>
                  )
                }
              </Route>
            ))
          }
        </Switch>
      </Router>
    </>
  )
}

export default AllRoutes;