/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, useState, useEffect } from 'react'
import VairantTables from './VairantTables';
import { Multiselect } from "multiselect-react-dropdown";
// import { AnyArn } from 'aws-sdk/clients/groundstation';


const styles = {
    optionStyle: {
        textTransform: "uppercase",
        background: "#FF0000",
        width: "-webkit-fill-available",
        maxWidth: "fit-content",
        borderRadius: "20px"
    } as CSSProperties,

    addIcon: {
        background: "#a01115",
        borderRadius: "20px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    } as CSSProperties,

    doneStyle: {
        background: "#105293",
        color: "white",
        width: "100px",
        borderRadius: "20px"
    } as CSSProperties,
    multiTag: {
        backgroundColor: "red",
        // color: "white",
    } as CSSProperties,

};

interface VariantsProps {
    dataList: any[];
}

const Variants: React.FC<VariantsProps> = ({ dataList }) => {
    const [initialDataList, setInitialDataList] = useState([]);
    const [optionValues, setoptionValues] = useState([0]);
    const [inputValues, setinputValues] = useState({})
    const [variatType, setVariantType]: any = useState([])
    const [dataSet, setdataSet]: any = useState({
        size: [],
        color: []
    });

    const colorMapping: any = {
        'abbey': '#4C4F56',
        'acadia': '#1B1404',
        'acapulco': '#7CB0A1',
        'aero blue': '#C9FFE5',
        'affair': '#714693',
        'akaroa': '#D4C4A8',
        'alabaster': '#FAFAFA',
        'albescent white': '#F5E9D3',
        'algae green': '#93DFB8',
        'alice blue': '#F0F8FF',
        'alizarin crimson': '#E32636',
        'allports': '#0076A3',
        'almond': '#EED9C4',
        'almond frost': '#907B71',
        'alpine': '#AF8F2C',
        'alto': '#DBDBDB',
        'aluminium': '#A9ACB6',
        'amaranth': '#E52B50',
        'amazon': '#3B7A57',
        'amber': '#FFBF00',
        'americano': '#87756E',
        'amethyst': '#9966CC',
        'amethyst smoke': '#A397B4',
        'amour': '#F9EAF3',
        'amulet': '#7B9F80',
        'anakiwa': '#9DE5FF',
        'antique brass': '#C88A65',
        'antique bronze': '#704A07',
        'anzac': '#E0B646',
        'apache': '#DFBE6F',
        'apple': '#4FA83D',
        'apple blossom': '#AF4D43',
        'apple green': '#E2F3EC',
        'apricot': '#EB9373',
        'apricot peach': '#FBCEB1',
        'apricot white': '#FFFEEC',
        'aqua deep': '#014B43',
        'aqua forest': '#5FA777',
        'aqua haze': '#EDF5F5',
        'aqua island': '#A1DAD7',
        'aqua spring': '#EAF9F5',
        'aqua squeeze': '#E8F5F2',
        'aquamarine': '#7FFFD4',
        'aquamarine blue': '#71D9E2',
        'arapawa': '#110C6C',
        'barley white': '#FFF4CE',
        'barossa': '#44012D',
        'bastille': '#292130',
        'battleship gray': '#828F72',
        'bay leaf': '#7DA98D',
        'bay of many': '#273A81',
        'black bean': '#081910',
        'black forest': '#0B1304',
        'black haze': '#F6F7F7',
        'black marlin': '#3E2C1C',
        'black olive': '#242E16',
        'black pearl': '#041322'
    };

    const colorCodeList = Object.entries(colorMapping).map(([colorName, hexColor]: any) => ({
        color_: colorName,
        hexColor: hexColor,
    }));

    const [sizeList] = useState([
        { size_: "UK1" }, { size_: "UK1.5" }, { size_: "UK2" }, { size_: "UK2.5" }, { size_: "UK3" },{ size_: "UK3.5" },{ size_: "UK4" },
        { size_: "UK4.5" },{ size_: "UK5" },{ size_: "UK5.5" },{ size_: "UK6" }, { size_: "UK7" },{ size_: "UK7.5" },{ size_: "UK8" },{ size_: "UK8.5" }
       ,{ size_: "UK9" },{ size_: "UK9.5" },{ size_: "UK10" },{ size_: "UK10.5" },
        { size_: "UK11" },{ size_: "UK11.5" },{ size_: "UK12" },{ size_: "UK12.5" },{ size_: "UK13" },{ size_: "UK13.5" }
    ])
    const [colorList] = useState(colorCodeList);
    const [selectedColor, setSelectedColor]: any = useState([]);
    const [selectedSize, setSelectedSize]: any = useState([]);

    function addHandler() {
        setoptionValues((count: any) => [...count, count[count.length - 1] + 1])
        // Alternate Append
        // setoptionValues((count: any) => {
        //     const lastValue = count[count.length - 1];
        //     const incrementBy = sizesArray.length - lastValue;
        //     console.log("sizesArray",sizesArray)
        //     return [...count, ...Array.from({ length: incrementBy }, (_, index) => lastValue + index + 1)];
        // });
    }

    function inputChangeHandler(e: any) {
        setinputValues({ ...inputValues, [e.target.name]: e.target.value })
    }

    // function onSizeDoneHandler() {
    //     let k: any = Object.values(inputValues)
    //     let sizeArray = k[0].split(',');
    //     let z: any = { ...dataSet, size: [...sizeArray] };
    //     setdataSet({ ...z })
    //     setinputValues({})
    //     setoptionValues([0])
    // }
    function onSizeDoneHandler() {
        let k: any = Object.values(inputValues);
        let sizeArray;
        if (k[0]) {
            sizeArray = k[0].split(',');
        } else {
            console.error("Error -> 'Size' is undefined or null.");
            return;
        }
        let z: any = { ...dataSet, size: [...sizeArray] };
        setdataSet({ ...z });
        setinputValues({});
        setoptionValues([0]);
    }

    function onColorDoneHandler() {
        let k: any = Object.values(inputValues);
        let colorsArray= [];
        if (k[0]) {
            colorsArray = k[0].split(',');
        } else {
            return console.error("Error -> 'Color' is undefined or null.");
        }
        let z: any = { ...dataSet, color: [...colorsArray] };
        setdataSet({ ...z });
        setinputValues({});
        setoptionValues([0]);
    }


    // For Size
    const onSelectSizeHandler = (selectedList: any[], s_Item: any) => {
        const s_Selected = selectedList.map((item: any) => item?.size_);
        const z = s_Selected.join(',');
        setinputValues({ ...inputValues, selectSize: z });
        setSelectedSize([...selectedList]);
    };
    const onRemoveSizeHandler = (selectedList: any[], s_Item: any) => {
        const s_Selected = selectedList.map((item: any) => item?.size_);
        const z = s_Selected.join(',');
        setinputValues({ ...inputValues, selectSize: z });
        setSelectedSize([...selectedList]);
    };

    // For color 
    const onSelectHandler = (selectedList: any[], c_Item: any) => {
        const colorSelected = selectedList.map((item) => item.color_);
        const z = colorSelected.join(',');
        setinputValues({ ...inputValues, selectColor: z });
        setSelectedColor([...selectedList]);
    };
    const onRemoveHandler = (selectedList: any[], c_Item: any) => {
        const colorSelected = selectedList.map((item) => item.color_);
        const z = colorSelected.join(',');
        setinputValues({ ...inputValues, selectColor: z });
        setSelectedColor([...selectedList]);
    };

    function convertData(dataList: any[]): { size: any[], color: any[] } {
        const leatestColor = dataList?.map((setColor: any) => setColor?.colour);
        const filteredColor = colorList?.filter((color_: any) => leatestColor?.includes(color_.color_)) || [];
        setSelectedColor(filteredColor);
        const leatestSize = dataList?.map((setSize: any) => setSize?.size);
        const filteredSize = sizeList?.filter((size_: any) => leatestSize?.includes(size_.size_)) || [];
        setSelectedSize(filteredSize);
        const sizesSet: any = new Set(dataList?.map(item => item?.size));
        const colorsSet: any = new Set(dataList?.map(item => item?.colour));
        return { size: [...sizesSet], color: [...colorsSet] };
    }
    // function convertData(dataList: any[]): { size: any[], color: any[] } {
    //     const leatestColor = dataList?.map((setColor: any) => setColor?.colour);
    //     const filteredColor = colorList?.filter((color_: any) => leatestColor?.includes(color_.color_)) || [];
    //     setSelectedColor(filteredColor);
    //     const leatestSize = dataList?.map((setSize: any) => setSize?.size);
    //     const filteredSize = sizeList?.filter((size_: any) => leatestSize?.includes(size_.size_)) || [];
    //     setSelectedSize(filteredSize);
    //     const sizesSet: Set<any> = new Set(dataList?.map(item => item?.size));
    //     const colorsSet: Set<any> = new Set(dataList?.map(item => item?.colour));
    //     const sizesArray = Array.from(sizesSet);
    //     const colorsArray = Array.from(colorsSet);
    //     return { size: sizesArray, color: colorsArray };
    // }


    useEffect(() => {
        const initDataList: any = [...dataList];
        if (initDataList?.length > 0) {
            setInitialDataList(initDataList)
        }
        if (dataList?.length > 0) {
            const convertedData = convertData(dataList);
            setdataSet(convertedData);
        }
    }, [dataList])



    console.log("Initial_dataList", dataList)
    console.log("dataSet", dataSet)
    console.log("inputValues", inputValues)

    return (
        <>
            {/* < div className="" style={{ background: "white", minHeight: "100px" }} >
                <div>
                    {
                        dataSet.size.length > 0 && <div> <p>Size</p>
                            <p>
                                {
                                    dataSet.size.map((item: any, index: any) => (
                                        <span className='badge badge-primary mx-1' style={styles?.optionStyle} key={index}>{item}</span>
                                    ))
                                }
                            </p>
                        </div>
                    }
                </div>
                <div>
                    {
                        dataSet.color.length > 0 && <div> <p>Color</p>
                            <p>
                                {
                                    dataSet.color.map((item: any, index: any) => (
                                        <span className='badge badge-primary mx-1' style={styles?.optionStyle} key={index}>{item}</span>
                                    ))
                                }
                            </p>
                        </div>
                    }
                </div>
                <hr className="sidebar-divider" />
                <div>
                    <div className="p-2">
                        <div className='w-50 mt-3'>
                            <label htmlFor="">Option Name</label>
                            <select name="" id="" className='form-control' onChange={(e) => setVariantType(e.target.value)}>
                                <option value="">Select Variant Type</option>
                                <option value="size">Size</option>
                                <option value="color">Colour</option>
                            </select>
                        </div>
                        <div className='w-50 mt-3'>
                            <label htmlFor="">Option Values</label>
                            {
                                optionValues.map((_item, index) => {
                                    return <div className='mt-2'>
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            <input type="text" className="form-control" key={index} name={`value${index}`} onChange={inputChangeHandler} />
                                            {optionValues[optionValues.length - 1] === index && <span className='badge badge-primary' style={styles?.addIcon}
                                                onClick={addHandler}><i className="fa fa-plus" aria-hidden="true"></i></span>}
                                        </div>
                                    </div>
                                })
                            }
                            {
                                variatType === "size" ? <button className='btn mt-3' onClick={onDoneHandler} style={{ background: "#105293", color: "white", width: "100px", borderRadius: "20px" }}>Done</button> : null
                            }
                            {
                                variatType === "color" ? <button className='btn mt-3' onClick={onColorDoneHandler} style={{ background: "#105293", color: "white", width: "100px", borderRadius: "20px" }}>Done</button> : null
                            }
                        </div>
                    </div>
                </div>
                <VairantTables dataSet={dataSet} ></VairantTables>
            </div> */}

            <div className="" style={{ background: "white", minHeight: "100px" }}>
                <div>
                    {dataSet.size.length > 0 && (
                        <div>
                            <p>Size</p>
                            <p>
                                {dataSet.size.map((item: any, index: any) => (
                                    <span className='badge badge-primary mx-1 px-2' style={styles?.optionStyle} key={index}>{item}</span>
                                ))}
                            </p>
                        </div>
                    )}
                </div>
                <div>
                    {dataSet.color.length > 0 && (
                        <div>
                            <p>Color</p>
                            <p>
                                {dataSet.color.map((item: any, index: any) => (
                                    <span className='badge badge-primary mx-1 px-2' style={styles?.optionStyle} key={index}>{item}</span>
                                ))}
                            </p>
                        </div>
                    )}
                </div>
                <hr className="sidebar-divider" />
                <div>
                    <div className="p-2">
                        <div className='w-50 mt-3'>
                            <label htmlFor="">Option Name</label>
                            <select name="" id="" className='form-control' onChange={(e) => setVariantType(e.target.value)}>
                                <option value="">Select Variant Type</option>
                                <option value="size">Size</option>
                                <option value="color">Color</option>
                            </select>
                        </div>
                        <div className='w-50 mt-3'>
                            {/* <label htmlFor="">Option Values</label> */}
                            {/* {variatType === "size" && (
                                optionValues.map((_item, index) => (
                                    <div className='mt-2' key={index}>
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            <input type="text" className="form-control" name={`value${index}`} onChange={(e) => inputChangeHandler(e)} />
                                            {optionValues[optionValues.length - 1] === index && (
                                                <span className='badge badge-primary' style={styles?.addIcon} onClick={addHandler}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                ))
                            )} */}
                            {variatType === "size" && (
                                <div className='mt-2 mx-lg-n2'>
                                    <div className="col-md-12 col-sm-12" style={{ zIndex: 999 }}>
                                        <label>Select Size</label>
                                        <Multiselect
                                            options={sizeList}
                                            selectedValues={selectedSize}
                                            onSelect={onSelectSizeHandler}
                                            onRemove={onRemoveSizeHandler}
                                            displayValue="size_"
                                            placeholder="Select Size"
                                        />
                                    </div>
                                </div>
                            )}
                            {variatType === "color" && (
                                <div className='mt-2 mx-lg-n2'>
                                    <div className="col-md-12 col-sm-12" style={{ zIndex: 999 }}>
                                        <label>Select Color</label>
                                        <Multiselect
                                            options={colorList}
                                            selectedValues={selectedColor}
                                            onSelect={onSelectHandler}
                                            onRemove={onRemoveHandler}
                                            displayValue="color_"
                                            placeholder="Select Color"
                                        />
                                    </div>
                                </div>
                            )}
                            {
                                variatType === "size" ? <button className='btn mt-3 mx-1' onClick={onSizeDoneHandler} style={styles?.doneStyle}>Done</button> : null
                            }
                            {
                                variatType === "color" ? <button className='btn mt-3 mx-1' onClick={onColorDoneHandler} style={styles?.doneStyle}>Done</button> : null
                            }
                        </div>
                    </div>
                </div>
                <VairantTables dataList={initialDataList} dataSet={dataSet}></VairantTables>
            </div>
        </>
    )
}

export default Variants;