import { useState, useEffect, CSSProperties } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { useCSVReader } from 'react-papaparse';
import jwt_decode from "jwt-decode";
import aws from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import config from "../../config/config";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Variants from "../../components/Variants/Variants";
import React from "react";
import Box from "@mui/material/Box";


const styles = {
    csvReader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    } as CSSProperties,
    browseFile: {

    } as CSSProperties,
    acceptedFile: {
        border: '1px solid #ccc',
        height: 45,
        lineHeight: 2.5,
        paddingLeft: 10,
        width: '50%',
    } as CSSProperties,
    remove: {
        borderRadius: 0,
        padding: '0 20px',
    } as CSSProperties,
    progressBarBackgroundColor: {
        backgroundColor: 'red',
    } as CSSProperties,
    variantsHeading: {
        fontFamily: "sans-serif",
        letterSpacing: "5px",
        fontSize: "15px",
        fontWeight: 900,
        backgroundColor: "aliceblue",
        borderRadius: "20px",
        width: "50%",
        margin: "auto",
        textAlign: "center",
        color: "#FF0000"
    } as CSSProperties,
    closeIcon: {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer"
    } as CSSProperties,
    smstyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        bgcolor: 'background.paper',
        borderRadius: "5px",
        // boxShadow: 24,
        // p: 4,
        minHeight: 400,
        maxHeight: 700,
        overflow: "scroll",
        color: "black",
    } as CSSProperties,
};
interface ICustomer {
    id: any;

}
const Addproduct = () => {
    const { baseUrl } = config;
    const { CSVReader } = useCSVReader();
    let params: ICustomer = useParams();
    // let history = useHistory();
    const { id } = params;
    const [pageNamem, setpageName] = useState(true);
    // Images 
    const [loading, setLoading] = useState(false);
    const [uploading, setUpLoading] = useState(false);
    // variants
    const [mopen, setmopen] = React.useState(false);
    const [iopen, setiopen] = React.useState(false);
    const handleOpen = () => setmopen(true);
    const handleImageOpen = () => setiopen(true);
    const handleClose = () => setmopen(false);
    const handleimageClose = () => setiopen(false);

    const rawData = {
        title: '',
        description: '',
        status: 'draft',
        images: '',
        price: '',
        compareprice: '',
        netweight: '',
        sku: '',
        barcode: '',
        hscode: '',
        vendor: '',
        tags: '',
        productid: '',
        rank: '',
        category_id: ''
    }

    const smstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70%",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: "5px",
        boxShadow: 24,
        p: 4,
        minHeight: 400,
        maxHeight: 700,
        overflow: "scroll",
        color: "black"
    };

    const [productData, setProductData]: any = useState(rawData);
    const [VariantData, setVariantData]: any = useState([]);

    const [csvData, setCsvData] = useState([]);
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { data: any } = decoded;

    const inputHandler = (e: any) => {
        setProductData({ ...productData, [e.target.name]: e.target.value });
    }


    const createProductHandler = async () => {
        setLoading(true);
        try {
            const { data } = await axios({
                url: `${baseUrl}/product/create`,
                method: "POST",
                headers: {
                    Authorization: ` ${token}`
                },
                data: productData
            });
            if (data.message === "flag1") {
                toast.error("Product Id is already available !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (data.message === "flag2") {
                toast.success("Product created successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setProductData(rawData);
            } else {
                toast.error("404 error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    const updateProductHanlder = async () => {
        try {
            setUpLoading(true);
            await axios({
                url: `${baseUrl}/product/update`,
                method: "PUT",
                headers: {
                    Authorization: ` ${token}`
                },
                data: productData

            });
            setUpLoading(false);
            localStorage.setItem('product', JSON.stringify(productData));
            toast.success("Product updated successfully !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            setUpLoading(false);
            toast.error("404 error ! please reach to support@ens.enterprises", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const importCsvHanlder = async () => {
        try {
            setLoading(true);
            const { data } = await axios({
                url: `${baseUrl}/product/csv/import`,
                method: "POST",
                headers: {
                    Authorization: ` ${token}`
                },
                data: { data: csvData }
            });
            setLoading(false);
            if (data.message === "flag1") {
                toast.success("CSV imported successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("error while importing !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            setLoading(false);
            toast.error("error while importing !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }



    const productImageHandler = async (e: any) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
                secretAccessKey: "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"
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;
            console.log(Location);

            setProductData({ ...productData, images: Location })
        } catch (exception) {
            console.log(exception);
        }

        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
                secretAccessKey: "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"
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;
            console.log(Location);

            setProductData({ ...productData, images: Location })
        } catch (exception) {
            console.log(exception);
        }
    };

    useEffect(() => {
        if (id) {
            setpageName(false);
            const user: any = localStorage.getItem("product");
            setProductData(JSON?.parse(user))
            setVariantData(JSON?.parse(user)?.variants)
        } else {
            setpageName(true)
        }
    }, [id]);
    const imgStyle = {
        fontSize: "10px",
        marginLeft: "3px",
        textDecoration: "underline"
    }

    console.log("productData", productData)
    console.log("productData.variants", productData.variants)
    console.log("VariantData", VariantData)

    return (
        <>
            <ThemeMaker>
                <div className="row">
                    <div className="col-md-12 pl-4 pr-4">
                        <div className="card shadow mt-1">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    {pageNamem ? "Create product" : "Update product"}
                                </h6>
                                {pageNamem ?
                                    <button className="btn btn-primary"
                                        data-toggle="modal" data-target="#exampleModal"
                                    > Import CSV </button> : null}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Title</label>
                                            <input type="text" className="form-control" name="title" placeholder="Enter title"
                                                value={productData.title}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Product status</label>
                                            <select name="status"
                                                className="form-control"

                                                onChange={(e) => inputHandler(e)}
                                            >
                                                <option selected={productData.status === "draft" ? true : false} value="draft">Draft</option>
                                                <option selected={productData.status === "active" ? true : false} value="active">Active</option>

                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label >Description</label>
                                            <textarea className="form-control" name="description" placeholder="Enter title"
                                                value={productData.description}
                                                onChange={(e) => inputHandler(e)}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*  */}
                            <div className="card-body py-0">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <h6 className="">Variants</h6>
                                            <button className="bg-transparent border-0" type="button" onClick={handleOpen}>
                                                <small className="text-primary"> <span> + </span> Add Options like | Category | Size | Color</small>
                                            </button>
                                            {/* <input type="text" className="form-control" name="title" placeholder="Enter title"
                                                    value={productData.title}
                                                    onChange={(e) => inputHandler(e)}
                                                /> */}

                                            {/* <div className="col-md-2 d-flex align-items-end" >
                                             <span className="badge badge-primary form-group" onClick={handleImageOpen} style={{ cursor: "pointer" }}>Add Images</span>
                                             </div> */}
                                        </div>
                                    </div>

                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <h6 className="">Variants</h6>
                                            <button className="bg-transparent border-0" type="button">
                                                <small className="text-primary"> <span> + </span> Add Options like Category | Size | Color</small>
                                            </button>
                                           
                                        </div>
                                    </div> */}

                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <h6 className="">Variants</h6>
                                            <button className="bg-transparent border-0" type="button">
                                                <small className="text-primary"> <span> + </span> Add Options like Category | Size | Color</small>
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/*  */}
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Price</label>
                                            <input type="number" className="form-control" name="price" placeholder="Enter price"
                                                value={productData.price}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Compare price</label>
                                            <input type="number" className="form-control" name="compareprice" placeholder="Enter compare price"
                                                value={productData.compareprice}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Net-Weight(gm)</label>
                                            <input type="number" className="form-control" name="netweight" placeholder="Enter compare price"
                                                value={productData.netweight}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >SKU</label>
                                            <input type="text" className="form-control" name="sku" placeholder="Enter sku"
                                                value={productData.sku}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Barcode</label>
                                            <input type="text" className="form-control" name="barcode" placeholder="Enter barcode"
                                                value={productData.barcode}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >HS code</label>
                                            <input type="text" className="form-control" name="hscode" placeholder="Enter HS code"
                                                value={productData.hscode}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Vendor</label>
                                            <input type="text" className="form-control" name="vendor" placeholder="Enter vendor"
                                                value={productData.vendor}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Tags</label>
                                            <input type="text" className="form-control" name="tags" placeholder="Enter tags"
                                                value={productData.tags}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product Id</label>
                                            <input type="number" className="form-control" name="productid" placeholder="Enter product Id"
                                                value={productData.productid}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Ranking</label>
                                            <input type="number" className="form-control" name="rank" placeholder="Ranking"
                                                value={productData.rank}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Category_id</label>
                                            <input type="text" className="form-control" name="category_id" placeholder="category_id"
                                                value={productData.category_id}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    {/*  */}
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label >manufacturer_or_packer_name</label>
                                            <input type="text" className="form-control" name="manufacturer_or_packer_name" placeholder="Enter Mnufacturer oR Packer Name"
                                                value={productData.vendor}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label >manufacturer OR Packer Address</label>
                                            <input type="text" className="form-control" name="manufacturer_or_packer_address" placeholder="Enter Manufacturer Or Packer Address"
                                                value={productData.vendor}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Common or Generic Name of Commodity</label>
                                            <input type="text" className="form-control" name="common_or_generic_name_of_commodity" placeholder="Enter Common or Generic Name of Commodity"
                                                value={productData.vendor}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Net Quantity OR Measure of Commodity in Per kg</label>
                                            <input type="text" className="form-control" name="net_quantity_or_measure_of_commodity_in_pkg" placeholder="Enter Net Quantity OR Measure of Commodity in Per kg"
                                                value={productData.vendor}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Month Year of Manufacture Packing Import</label>
                                            <input type="text" className="form-control" name="month_year_of_manufacture_packing_import" placeholder="Enter Month Year of Manufacture Packing Import"
                                                value={productData.vendor}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Imported Product Country of Origin</label>
                                            <input type="text" className="form-control" name="imported_product_country_of_origin" placeholder="Enter Imported Product Country of Origin"
                                                value={productData.vendor}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div> */}
                                    {/*  */}

                                    {/*  */}
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Images</label>
                                            <span className="text-danger" > * </span>
                                            {/* {
                                                productDataError?.image ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            } */}
                                            {productData.images && <a href={productData.images} target="_blank" style={imgStyle} rel="noreferrer">View Document</a>}
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => productImageHandler(e)}
                                                accept="image/*"
                                                name="images"
                                                multiple
                                                // onBlur={(e) => errorHandler(e)}
                                                id="image"
                                                style={{ lineHeight: "1" }}
                                            />
                                        </div>
                                    </div>
                                    {/*  */}

                                    <div className="col-md-12 text-right">
                                        {
                                            pageNamem ? (
                                                <button type="submit" className="btn btn-primary"

                                                    onClick={createProductHandler}>
                                                    {
                                                        loading ? 'Loading...' : "Create"
                                                    }
                                                </button>
                                            ) : (
                                                <button type="submit" className="btn btn-success"
                                                    onClick={updateProductHanlder}
                                                >
                                                    {
                                                        uploading ? 'Loading...' : "Update"
                                                    }
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="modal fade" id="exampleModal" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <h5 className="modal-title text-center"></h5> */}
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <CSVReader onUploadAccepted={(results: any) => { results.data.splice(0, 1); setCsvData(results.data); }} >
                                        {({ getRootProps,
                                            acceptedFile,
                                            ProgressBar,
                                            getRemoveFileProps,
                                        }: any) => (
                                            <>
                                                <div style={styles.csvReader}>
                                                    <button type='button' className="btn btn-primary" {...getRootProps()} style={styles.browseFile}>
                                                        Browse file
                                                    </button>
                                                    <div style={styles.acceptedFile}>
                                                        {acceptedFile && acceptedFile.name}
                                                    </div>
                                                    <button className="btn btn-danger"  {...getRemoveFileProps()} style={styles.remove}>
                                                        Remove
                                                    </button>
                                                </div>
                                                <ProgressBar style={styles.progressBarBackgroundColor} />
                                            </>
                                        )}
                                    </CSVReader>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success"
                                    onClick={importCsvHanlder}
                                >
                                    {
                                        loading ? 'Loading...' : "Import"
                                    }
                                </button>
                                <button type="button" id="closebtn" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={mopen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
                // onClose={handleClose}
                >
                    <Box sx={smstyle}>
                        <CloseIcon style={styles.closeIcon} onClick={handleClose} />
                        <p className="" style={styles?.variantsHeading}>Variants</p>
                        <div>
                            <Variants dataList={VariantData} ></Variants>
                        </div>
                    </Box>
                </Modal>
            </ThemeMaker >
        </>
    )
}

export default Addproduct;

// function uuidv4() {
//     throw new Error("Function not implemented.");
// }
