import React from 'react'
import { useState, useRef } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import { Link } from 'react-router-dom';
// import { config } from "../../utils/config";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import config from "../../config/config";


const s3Config = {
    bucketName: 'bucket-name',
    dirName: 'directory-name',
    region: 'ap-south-1',
    accessKeyId: 'ABCD12EFGH3IJ4KLMNO5',
    secretAccessKey: 'a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0',
    s3Url: 'https:/your-aws-s3-bucket-url/'     /* Optional */
}
const aws = require('aws-sdk');

export default function Bankdetails() {
    const { baseUrl } = config;
    const token = sessionStorage.getItem("isLogedIn");
    const decoded = jwt_decode(token);
    const { data } = decoded;

    const param = useParams()
    const id = param.id

    const history = useHistory()

    const rawData = {
        id: id,
        bank_name: "",
        upi_address: "",
        bank_acc_no: "",
        ifsc_code: "",
        beneficiary_name: "",
        bank_branch_name: "",

    }
    const rawErrorData = {
        bank_name: false,
        upi_address: false,
        bank_acc_no: false,
        ifsc_code: false,
        beneficiary_name: false,
        bank_branch_name: false,
        commission: false,
        ondc_commission: false,
        ondc_commission: false,
        seller_source: false,
    }
    const [bankdetailsData, setbankdetailsData] = useState(rawData);
    const [bankdetailsDataError, setbankdetailsDataError] = useState(rawErrorData);
    const renderAfterCalled = useRef(false);
    if (!renderAfterCalled.current) {
        givedta()
    }
    async function givedta() {
        const { data } = await axios({
            url: `${baseUrl}/user/getbankdetail`,
            method: "GET",
            headers: {
                Authorization: ` ${token}`
            },
            data: {
                seller_id: id
            }
        });
        // setbankdetailsData(data?.data?.[0] ?? null);
        if (data.data.length !== 0) {
            setbankdetailsData(data.data[0])
        }
        renderAfterCalled.current = true;
        if (data.status === 200) {
            // setbankdetailsData(data.data[0])
            // renderAfterCalled.current = true;
        }

    }

    const inputHandler = (e) => {
        setbankdetailsData({ ...bankdetailsData, [e.target.name]: e.target.value });
    }
    const errorHandler = (e) => {
        if (e.target.value) {
            setbankdetailsDataError({ ...bankdetailsDataError, [e.target.name]: "valid" });
        } else {
            setbankdetailsDataError({ ...bankdetailsDataError, [e.target.name]: true });
        }
    }

    const onSubmitHandler = async () => {
        // console.log("bankdetailsData => ", bankdetailsData);
        // console.log("bankdetailsDataError => ", bankdetailsDataError);
        const { id,
            bank_name,
            upi_address,
            bank_acc_no,
            ifsc_code,
            beneficiary_name,
            bank_branch_name,
        } = bankdetailsData;
        if (bank_name && upi_address && bank_acc_no && ifsc_code && beneficiary_name && bank_branch_name) {
            // dispatch(registerSeller(bankdetailsData));
            // console.log(">>>>>>>>>>>>", bankdetailsData)
            try {
                const { data } = await axios({
                    url: `${baseUrl}/user/bankdetail`,
                    method: "POST",
                    headers: {
                        Authorization: ` ${token}`
                    },
                    data: bankdetailsData,
                })
                toast.success("Details submitted Successfully", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // if (data.status === 200) {
                //     toast.success("Details submitted Successfully", {
                //         position: "top-right",
                //         autoClose: 2000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //     });
                //     setTimeout(() => {
                //         if (decoded.data.user.approve_status === 0) {
                //             history.push("/pending")
                //         }
                //     }, 2000)
                // }
                // console.log("data from server---", data)
                // console.log("data from server---", bankdetailsData)

            } catch (err) {
                console.log(err)
            }
        } else {
            toast.error("Please fill all the fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const productImageHandler = async (e) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
                secretAccessKey: "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"
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;
            // console.log(Location);
            setbankdetailsData({ ...bankdetailsData, [e.target.name]: Location })
        } catch (exception) {
            console.log(exception);
        }
    };

    return (

        <div className="row m-0 w-100 mt-1">
            <div className="col-md-12 col-sm-12">
                {/* <div className="card shadow mb-4"> */}
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label >
                                        Bank Name
                                        <span className="text-danger" > * </span>
                                        {
                                            bankdetailsDataError?.bank_name !== "valid" && bankdetailsDataError?.bank_name !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="bank_name" placeholder="Enter Bank Name"
                                        value={bankdetailsData.bank_name}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label >Bank Account Number<span className="text-danger" >*</span>
                                        {
                                            bankdetailsDataError?.bank_acc_no !== "valid" && bankdetailsDataError?.bank_acc_no !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="bank_acc_no" placeholder="Enter Account Number"
                                        value={bankdetailsData.bank_acc_no}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label >IFSC Code<span className="text-danger" >*</span>
                                        {
                                            bankdetailsDataError?.ifsc_code !== "valid" && bankdetailsDataError?.ifsc_code !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" name="ifsc_code" className="form-control" placeholder="Enter IFSC Code"
                                        value={bankdetailsData.ifsc_code}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className="form-group">
                                    <label >Beneficiary Name<span className="text-danger" >*</span>
                                        {
                                            bankdetailsDataError?.beneficiary_name !== "valid" && bankdetailsDataError?.beneficiary_name !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" name="beneficiary_name" className="form-control" placeholder="Enter Benificiary Name"
                                        value={bankdetailsData.beneficiary_name}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label >Bank Branch Name<span className="text-danger" >*</span>
                                        {
                                            bankdetailsDataError?.bank_branch_name !== "valid" && bankdetailsDataError?.bank_branch_name !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="bank_branch_name" placeholder="Enter Branch Name"
                                        value={bankdetailsData.bank_branch_name}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label >UPI Address <span className="text-danger" >*</span>
                                        {
                                            bankdetailsDataError?.upi_address !== "valid" && bankdetailsDataError?.upi_address !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="upi_address" placeholder="Enter UPI Address"
                                        value={bankdetailsData.upi_address}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>


                            <div className="col-md-12 d-flex justify-content-end">
                                <button type="submit" className="btn btn-success" onClick={onSubmitHandler}>
                                    Update
                                </button>
                            </div>
                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                    </div>
                </div>
            </div>
        // </div>


    )
}
